[data-component="CreateInvoiceForm"] {

  .button-submit-container {
    display: flex;
    gap: 1rem;

    flex-direction: row-reverse;

    @media(max-width: 640px) {
      flex-direction: column;
    }
  }
}
